import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Section from "../components/Section"
import { Checkbox, Input, TextArea } from "../components/inputs"
import { useForm, ValidationError } from "@formspree/react"
import {
  Header1,
  Header1Accent,
  Caption,
  Body,
  Bold,
} from "../components/Typography"
import Button from "../components/Button"
import { FiArrowRight } from "react-icons/fi"
import { GatsbyImage } from "gatsby-plugin-image"

import { graphql } from "gatsby"
import opengraph from "../images/opengraph.png"

const DemoFormContainer = styled.form`
  width: 100%;
  max-width: 710px;
  margin: 0 auto;
  background: var(--base000);
  display: grid;
  grid-auto-flow: row;
  gap: 32px;
  padding: 32px;
  
  textarea {
     resize: vertical;
  }
`

const InputGroup = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 16px;
`

const LightImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  width: 100vw;
  bottom: 0;
  z-index: -2;
  left: 50%;
  transform: translate(-50%, 0);
`

const DarkImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  width: 100vw;
  bottom: 0;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);

  display: none;
  overflow: hidden;

  @media screen and (prefers-color-scheme: dark) {
    display: block;
  }
`

function DemoForm() {
  // const [state, handleSubmit] = useForm("xzboznrb")

  // Production Formspree ID
  // const [state, handleSubmit] = useForm("melzekqx")

  // Fix Formspree ID since Production doesn't work
  const [state, handleSubmit] = useForm("meqpdanq")

  if (state.succeeded) {
    document.documentElement.scrollTop = 0
    return (
      <DemoFormContainer>
        <Body>Thanks for your submission!</Body>
      </DemoFormContainer>
    )
  }

  return (
    <DemoFormContainer onSubmit={handleSubmit}>
      <Header1>
        Personalize your <Header1Accent>free</Header1Accent> demo
      </Header1>
      <InputGroup>
        <Input
          id="demo-name"
          type="text"
          name="demo-name"
          placeholder="Name"
          required
          autoComplete="name"
        />
        <Input
          id="demo-email"
          type="email"
          name="demo-email"
          placeholder="Email"
          required
        />
      </InputGroup>
      <InputGroup>
        <Bold>What can we help you with?</Bold>
        <Checkbox
          id="Aligning engagement with fundraising efforts"
          name="interest"
        />
        <Checkbox id="Pipeline building" name="interest" />
        <Checkbox
          id="Providing DOs with prospect insights for cultivation"
          name="interest"
        />
        <Checkbox
          id="Measuring and increasing “Willingness to Give”"
          name="interest"
        />
        <Checkbox
          id="Strengthening emotional connection and alumni identity"
          name="interest"
        />
        <Checkbox id="Finding lost alumni" name="interest" />
        <Checkbox id="Update and enhance alumni data" name="interest" />
        <Checkbox id="Increasing event attendance" name="interest" />
        <Checkbox id="Delegating event management abilities" name="interest" />
      </InputGroup>
      <InputGroup>
        <label htmlFor="comments">
          <Body>Have something specific in mind? Let us know!</Body>
        </label>
        <TextArea name="comments" rows={5} />
      </InputGroup>{" "}
      <input type="text" name="_gotcha" style={{ display: "none" }} />
      <button type="submit" style={{ justifySelf: "start" }}>
        <Button primary>
          Submit Demo Request <FiArrowRight />
        </Button>
      </button>
      <Caption style={{ color: "var(--error100)" }}>
        <ValidationError errors={state.errors} />
      </Caption>
    </DemoFormContainer>
  )
}

function DemoPage({ data }) {
  return (
    <Layout pageTitle="Request a Demo" image={opengraph}>
      <Section style={{ position: "relative" }}>
        <DemoForm />
        <LightImage
          image={data.lightImage.nodes[0].childImageSharp.gatsbyImageData}
          style={{ position: "absolute" }}
        />
        <DarkImage
          image={data.darkImage.nodes[0].childImageSharp.gatsbyImageData}
          style={{ position: "absolute" }}
        />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    lightImage: allFile(
      filter: {
        relativePath: { eq: "demo/gradienta-zb01fkevaC0-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
    darkImage: allFile(
      filter: {
        relativePath: { eq: "demo/gradienta-gPK5YsNW7kY-unsplash.jpg" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
`

export default DemoPage
