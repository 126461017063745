import * as React from "react"
import { FiCheck } from "react-icons/fi"
import styled from "styled-components"
import { Body } from "./Typography"

const Input = styled.input`
  color: var(--base100);
  font-size: 21px;
  line-height: 32px;
  height: 50px;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 0 20px;
  width: 100%;

  border: 1px solid var(--base070);
  border-radius: 2px;

  outline: none;
  background: none;

  :focus {
    border: 1px solid var(--base100);
  }

  ::placeholder {
    color: var(--base070);
  }
`

const StyledCheckbox = styled.input`
  appearance: none;
  background-color: var(--base000);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.3em;
  height: 1.3em;
  border: 1px solid var(--base070);
  border-radius: 0.15em;

  + svg {
    scale: 0.75;
    opacity: 0;
    transition: opacity 150ms ease-in-out, scale 150ms ease-in-out;
  }

  :checked + svg {
    scale: 1;
    opacity: 1;
  }

  :checked {
    border: 1px solid var(--base100);
  }
`

const StyledCheckboxContainer = styled.label`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: start;
  justify-content: start;
  gap: 32px;
  cursor: pointer;

  :hover {
    input {
      border: 1px solid var(--base100);
    }
  }
`

const StyledCheckmark = styled(FiCheck)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  pointer-events: none;
`

const TextArea = styled.textarea`
  outline: none;
  border: 1px solid var(--base100);
  background: var(--base000);
  color: var(--base100);
  border-radius: 2px;
  padding: 10px 20px;
`

const Checkbox = props => {
  return (
    <StyledCheckboxContainer for={props.id}>
      <div
        style={{
          position: "relative",
          alignSelf: "start",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledCheckbox
          type="checkbox"
          name={props.name}
          id={props.id}
          value={props.id}
        />
        <StyledCheckmark />
      </div>
      <Body
        style={{
          "-webkit-touch-callout": "none" /* iOS Safari */,
          "-webkit-user-select": "none" /* Safari */,
          "-khtml-user-select": "none" /* Konqueror HTML */,
          "-moz-user-select": "none" /* Old versions of Firefox */,
          "-ms-user-select": "none" /* Internet Explorer/Edge */,
          "user-select": "none",
        }}
      >
        {props.id}
      </Body>
    </StyledCheckboxContainer>
  )
}

export { Input, Checkbox, TextArea }
